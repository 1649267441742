import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { PageHeader } from 'client/js/util/layout_utils';
import { toast, ToastContainer } from 'react-toastify';

const fetchListingStatus = async (token) => {
  const response = await $.ajax({
    url: `/paypal/landing-status.json?token=${token}`,
    dataType: 'json',
  });

  return response;
};

const Status = ({state}) => {
  if(state === 'new') {
    return (
      <>
        <div className="text-center">
          <span className="css-spinloader fullpage-spinner"></span>

          <p className="font-weight-bold">Warte auf Zahlungsbestätigung...</p>
        </div>
      </>
    );
  }

  if(state === 'captured') {
    return (
      <>
        <div className="text-center">
          <span className="css-spinloader fullpage-spinner"></span>

          <p className="font-weight-bold">Bestellung wird verarbeitet...</p>
        </div>
      </>
    );
  }

  if(state === 'processed') {
    return (
      <div className="text-center">
        <h4 className="text-success">
          <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
            <source src="/assets/ani/success.mp4" type="video/mp4" />
          </video>

          <br/>

          Zahlung erfolgreich verarbeitet!
        </h4>
      </div>
    );
  }

  return null;
}

const PaypalLanding = ({token}) => {
  const { data, isLoading } = useQuery(['/paypal/landing-status', token], () => fetchListingStatus(token), {
    refetchInterval: (data) => (data?.state !== 'processed') ? 1000 : false
  });

  const state = data?.state;

  if(isLoading) {
    return (
    <>
      <PageHeader text="PayPal-Zahlung" />

      <div className="text-center">
        <span className="css-spinloader fullpage-spinner"></span>

        <p className="font-weight-bold">Laden...</p>
      </div>
    </>
    );
  }

  return (
    <>
      <PageHeader text="PayPal-Zahlung" />

      <Status state={state} />
    </>
  );
}


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // do not retry if the error is a client error
        if (error.status >= 400 && error.status < 500) {
          return false;
        }

        return failureCount < 3;
      },
      refetchOnWindowFocus: false,
    },
  }
});

const PageWrapper = ({token}) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <PaypalLanding token={token} />
      </QueryClientProvider>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </>
  )
}

export default PageWrapper;
