/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

/* Thiis pack is for the public website */

import 'react-credit-cards/lib/styles-compiled.css';
import 'react-toastify/dist/ReactToastify.css';

import ApprovePaymentIntent from "client/js/approve_payment_intent";
import Cart from "client/js/cart/app";
import DomainCheck from "client/js/domain_check/app";
import PaypalLanding from "client/js/payment_methods/paypal_landing";
import UpdateMailboxPassword from "client/js/update_mailbox_password";
import InvoiceAddFunds from "client/js/usercp/invoices/add_funds";
import PayButton from "client/js/usercp/lib/payment";
import StandaloneUsercpNav from "client/js/usercp/navigation";
import ReactOnRails from 'react-on-rails';

ReactOnRails.register({
  DomainCheck,
  Cart,
  PayButton,
  StandaloneUsercpNav,
  UpdateMailboxPassword,
  ApprovePaymentIntent,
  InvoiceAddFunds,
  PaypalLanding
});
